import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Resheedah's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">“The changes I noticed were profound, but subtle. I frequently recommend the programs to others."</h1>
              <div className="div-block"><img width={74} src="/fonts/5ddce2572111937b8a12089b_11-People%20Avatar-Woman.svg" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Rasheedah</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5dc99dd0c36766947e593909_envato-image.jpg" alt="" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Rasheedah</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>Love Tapes, Alpha Break, Blood Pressure, Super Strength Memory Power and Immunity and Longevity </div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>My sons are now physicians in practice together. Both are married with children of their own</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>Rasheedah came across the Love Tapes in the early 1980s. “I was the newly divorced mother of 4 year-old twin sons and completing a degree in Psychology. I didn’t have specific areas of my life that I was dissatisfied with. I just wanted to reinforce key life principles and was seeking support.” She also wanted a positive foundation for her twins. “I wanted to give my sons a head start in building successful lives both spiritually and physically.”</p>
                  <p>After finding the Loves Tapes helpful, Rasheedah purchased other programs from Effective Learning Systems. “We listened to Alpha Break almost every morning prior to starting the day. In the afternoons, my sons often listened to Effective Studying and Test Taking, Peak Performance in Sports or Self-Image for Children. We used the programs related to self-confidence, relaxation, effective speaking, self-esteem, health and healing, relaxation, concentration, and freedom from stress and worry. I now use the Blood Pressure, Super Strength Memory Power and Immunity and Longevity programs as well as the Effective Meditations and While-U-Drive CD’s. I actually listen to Achieve Your Ideal Weight Auto-matically while on the treadmill from time to time.”</p>
                  <p>Just as some families watch TV together, Rasheedah’s family listened to Wise Guides programs at the same time. “We usually listened to our tapes once we retired for the night since we knew that we were absorbing the knowledge subliminally even if we fell asleep. Our cassette player was in the hall between our respective bedrooms so we could all hear the chosen tape.”</p>
                  <p>When asked about the effect on her life, Rasheedah says, “The changes I noticed were profound, but subtle. I frequently recommend the programs to others. In fact, I’m planning to gift my sons with the stress relief and anger management programs just in case they need a refresher.”</p>
                  <p>How did the programs affect her sons? “My sons are now 35 years-old and physicians in practice together. Both are married with children of their own.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
